import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./BasicPage.module.css";

function BasicPage({ children, mainClassName }) {
    return (
        <div>
            <Header dark={true} />
            <main id={styles.main_wrapper} className={mainClassName}>
                <div id={styles.main}>{children}</div>
            </main>
            <Footer />
        </div>
    );
}

export default BasicPage;
