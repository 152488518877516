import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Policy from "../../components/Policy/Policy";
import usePageTitle from "../../hooks/usePageTitle";

function TermsAndConditionsPage() {
    usePageTitle("Terms and Conditions");
    return (
        <div>
            <Header dark={true} />
            <main>
                <Policy title="Terms & Conditions">
                    <div>
                        <h2>Definition(s):</h2>
                        <h2>
                            Client is the Company contracted to Keel Mind Inc.
                        </h2>
                        <h2>Vendor is Keel Mind Inc.</h2>
                    </div>
                    <div>
                        <h2>CONFIDENTIALITY</h2>
                        <p>
                            Confidential information (the "Confidential
                            Information") refers to any data or information
                            relating to the business of the Client which would
                            reasonably be considered to be proprietary to the
                            Client including, but not limited to, accounting
                            records, business processes, and client records and
                            that is not generally known in the industry of the
                            Client and where the release of that Confidential
                            Information could reasonably be expected to cause
                            harm to the Client. The Vendor agrees that they will
                            not disclose, divulge, reveal, report or use, for
                            any purpose, any Confidential Information which the
                            Vendor has obtained, except as authorized by the
                            Client or as required by law. The obligations of
                            confidentiality will apply during the Term and will
                            survive indefinitely upon termination of this
                            Agreement.
                        </p>
                        <p>
                            All written and oral information and material
                            disclosed or provided by the Client to the Vendor
                            under this Agreement is Confidential Information
                            regardless of whether it was provided before or
                            after the date of this Agreement or how it was
                            provided to the Vendor.
                        </p>
                    </div>
                    <div>
                        <h2>Ownership of Data & Intellectual Property</h2>
                        <p>
                            All intellectual property and related material (the
                            "Intellectual Property") that is developed or
                            produced under this Agreement, will be the property
                            of the Vendor. The Client is granted a non-exclusive
                            limited use license of this Intellectual Property.
                            Any software produced during the course of this
                            Agreement may not be modified, reverse-engineered,
                            or de-compiled in any manner through current or
                            future available technologies. Get-A-Head does
                            retain aggregated, non-personally identifiable
                            information that will be used for the improvement of
                            the platform. Title, copyright, intellectual
                            property rights and distribution rights of the
                            Intellectual Property remain exclusively with the
                            Vendor. Intellectual property rights include the
                            look and feel of any software produced.
                        </p>
                        <p>
                            We know that customers care deeply about privacy and
                            data security. That’s why Keel Mind gives you
                            ownership and control over your content through
                            simple, powerful tools that allow you to determine
                            how this data is managed and retained. The Keel Mind
                            platform is a PaaS (platform as a service) solution
                            where the Keel Mind organization operates the
                            platform on behalf of the organizations who are our
                            customers. With this in mind, all customer data is
                            owned by the organization customer.
                        </p>
                        <p>
                            Keel Mind is a data custodian of this customer data,
                            and we protect this data on behalf of our
                            organization customer. The impact of the above
                            approach is that Keel Mind is both reliant and
                            supportive of the organization's data retention and
                            data management policies and processes. We provide
                            the tools for manipulating, adding, removing and
                            expunging all the customer data in support of your
                            internal processes. More specifically, this impacts
                            the typical touchpoints: Data Retention policies
                            Data Privacy policies
                        </p>
                    </div>
                    <div>
                        <h2>RETURN OF PROPERTY</h2>Upon the expiry or
                        termination of this Agreement, the Vendor will return to
                        the Client any property, documentation, records, or
                        Confidential Information which is the property of the
                        Client.
                    </div>
                    <div>
                        <h2>CAPACITY/INDEPENDENT VENDOR</h2>In providing the
                        Services under this Agreement it is expressly agreed
                        that the Vendor is acting as an independent Vendor and
                        not as an employee. The Vendor and the Client
                        acknowledge that this Agreement does not create a
                        partnership or joint venture between them, and is
                        exclusively a contract for service.
                    </div>
                    <div>
                        <h2>RIGHT OF SUBSTITUTION</h2>
                        <ol>
                            <li>
                                Except as otherwise provided in this Agreement,
                                the Vendor may, at the Contractor's absolute
                                discretion, engage a third party sub-contractor
                                to perform some or all of the obligations of the
                                Vendor under this Agreement and the Client will
                                not hire or engage any third parties to assist
                                with the provision of the Services.
                            </li>
                            <li>
                                In the event that the Vendor hires a
                                sub-contractor:
                            </li>
                            <li>
                                The Vendor will pay the sub-contractor for its
                                services and the Compensation will remain
                                payable by the Client to theVendor.
                            </li>
                            <li>
                                for the purposes of the indemnification clause
                                of this Agreement, the sub-contractor is an
                                agent of the Vendor.
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h2>AUTONOMY</h2>
                        Except as otherwise provided in this Agreement, the
                        Vendor will have full control over working time,
                        methods, and decision making in relation to provision of
                        the Services in accordance with the Agreement. The
                        Vendor will work autonomously and not at the direction
                        of the Client. However, the Vendor will be responsive to
                        the reasonable needs and concerns of the Client.
                    </div>
                    <div>
                        <h2>INDEMNIFICATION</h2>Except to the extent paid in
                        settlement from any applicable insurance policies, and
                        to the extent permitted by applicable law, each Party
                        agrees to indemnify and hold harmless the other Party,
                        and its respective directors, shareholders, affiliates,
                        officers, agents, employees, and permitted successors
                        and assigns against any and all claims, losses, damages,
                        liabilities, penalties, punitive damages, expenses,
                        reasonable legal fees and costs of any kind or amount
                        whatsoever, which result from or arise out of any act or
                        omission of the indemnifying party, its respective
                        directors, shareholders, affiliates, officers, agents,
                        employees, and permitted successors and assigns that
                        occurs in connection with this Agreement. This
                        indemnification will survive the termination of this
                        Agreement.
                    </div>
                    <div>
                        <h2>MODIFICATION OF AGREEMENT</h2>
                        Any amendment or modification of this Agreement or
                        additional obligation assumed by either Party in
                        connection with this Agreement will only be binding if
                        evidenced in writing signed by each Party or an
                        authorized representative of each Party.
                    </div>
                    <div>
                        <h2>ASSIGNMENT</h2>The Vendor will not voluntarily, or
                        by operation of law, assign or otherwise transfer its
                        obligations under this Agreement without the prior
                        written consent of the Client.
                    </div>
                    <div>
                        <h2>ENTIRE AGREEMENT</h2>It is agreed that there is no
                        representation, warranty, collateral agreement or
                        condition affecting this Agreement except as expressly
                        provided in this Agreement.
                    </div>
                    <div>
                        <h2>ENUREMENT</h2>This Agreement will ensure to the
                        benefit of and be binding on the Parties and their
                        respective heirs, executors, administrators and
                        permitted successors and assigns.
                    </div>
                    <div>
                        <h2>TITLES/HEADINGS</h2>
                        Headings are inserted for the convenience of the Parties
                        only and are not to be considered when interpreting this
                        Agreement.
                    </div>
                    <div>
                        <h2>GENDER</h2>Words in the singular mean and include
                        the plural and vice versa. Words in the masculine mean
                        and include the feminine and vice versa.
                    </div>
                    <div>
                        <h2>GOVERNING LAW</h2>This Agreement will be governed by
                        and constructed in accordance with the laws of the Laws
                        of the State of Delaware.
                    </div>
                    <div>
                        <h2>SEVERABILITY</h2>In the event that any of the
                        provisions of this Agreement are held to be invalid or
                        unenforceable in whole or in part, all other provisions
                        will nevertheless continue to be valid and enforceable
                        with the invalid or unenforceable parts severed from the
                        remainder of this Agreement.
                    </div>
                    <div>
                        <h2>WAIVER</h2>
                        The waiver by either Party of a breach, default, delay
                        or omission of any of the provisions of this Agreement
                        by the other Party will not be construed as a waiver of
                        any subsequent breach of the same or other provisions.
                    </div>
                </Policy>
            </main>
            <Footer />
        </div>
    );
}

export default TermsAndConditionsPage;
