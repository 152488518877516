import { useState } from "react";
import styles from "./HeaderDropdown.module.css";

function HeaderDropdown({ text, children, className, id, style }) {
    const [showDropdown, setShowDropdown] = useState(false);

    function handleMouseEnter() {
        setShowDropdown(true);
    }

    function handleMouseLeave() {
        setShowDropdown(false);
    }

    return (
        <div
            className={styles.header_dropdown_container}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <button
                className={[styles.header_dropdown, className].join(" ")}
                id={id}
                style={style}
            >
                {text}
                <svg
                    className={styles.arrow}
                    width="28"
                    height="16"
                    viewBox="0 0 28 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 2L14 14L26 2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <div
                className={[
                    styles.children_container_buffer,
                    showDropdown ? styles.show : undefined,
                ].join(" ")}
            >
                <div className={styles.children_container}>{children}</div>
            </div>
        </div>
    );
}

export default HeaderDropdown;
