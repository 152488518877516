import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Policy from "../../components/Policy/Policy";
import usePageTitle from "../../hooks/usePageTitle";

function PrivacyPolicyPage() {
    usePageTitle("Terms of Use");

    return (
        <div>
            <Header dark={true} />
            <main>
                <Policy title="Terms of Use">
                    <h2>1. GETTING STARTED</h2>
                    <div>
                        <h3>1.1 About these Terms</h3>
                        Welcome to Keel Mind! We provide a platform-as-a-service
                        solution that allows third-party services providers to
                        provide higher quality online services (the “Platform”),
                        including via our mobile application (the “App”) and via
                        our website at www.keelmind.com (the “Website”, and
                        together with the App and the Platform, the “Services”).
                    </div>
                    <div>
                        To access or use our Services, you must agree to be
                        bound by these terms of us ("Terms"), which constitute a
                        legal agreement between you ("you”, “your”, or “user”)
                        and Keel Mind Inc. (“Keel Mind”, "us", "we", or "our")
                        and which sets out the terms and conditions for your
                        access or use of our Services.
                    </div>
                    <div>
                        By accessing or using our Services (including via a
                        third-party service provider) or otherwise indicating
                        your consent to these Terms, you agree to be bound by
                        these Terms and the documents that are referred to in
                        these Terms (including our Privacy Policy). If you do
                        not want to agree to these Terms, you must not access or
                        use our Services. By accessing or using our Services,
                        you represent to us that you are a legal resident of
                        Canada or the United States of America, are the legal
                        age of majority under applicable law to form a binding
                        contract with us, and are not prohibited by applicable
                        law from accessing or using our Services.
                    </div>
                    <div>
                        If you are not the legal age of majority under
                        applicable law, you are not able to agree to these Terms
                        and must have your parent or legal guardian agree to
                        these Terms on your behalf. If you are accessing or
                        using our Services on behalf of an organization or
                        institution, you acknowledge that you are agreeing to
                        these Terms on behalf of such organization or
                        institution, and you represent and warrant to us that
                        you have the requisite authority and approval to do so.
                    </div>
                    <div>
                        <h3>1.2 Types of Users</h3>
                        There are two different users of our Services
                        contemplated by these Terms:
                        <ul>
                            <li>
                                third-party services providers that incorporate
                                our Services into the services they provide or
                                their other commercial activities (referred to
                                in these Terms as “Service Providers”); and
                            </li>
                            <li>
                                end users who are using our Services either
                                directly or via Service Providers without
                                further incorporating our Services into services
                                or other commercial activities undertaken by
                                such end users (referred to in these Terms as
                                “End Users”).
                            </li>
                        </ul>
                    </div>
                    <div>
                        Except where a provision of these Terms explicitly
                        applies to either Service Providers or End Users, these
                        Terms apply to all users of our Services including
                        Service Providers or End Users.
                    </div>
                    <div>
                        If you are a Service Provider, you are also by our
                        third-party service provider terms located here.
                    </div>
                    <div>
                        If you are an End User using our Services via a Service
                        Provider:
                        <ul>
                            <li>
                                As between you and us, your access and use of
                                our Services are governed solely by these Terms
                                and these Terms will prevail to the extent of
                                any conflict or inconsistency with any terms of
                                any agreement you may have with a Service
                                Provider. For the avoidance of doubt, a Service
                                Provider may not grant you rights or benefits in
                                respect of the our Services that are
                                inconsistent with or going beyond the rights or
                                benefits granted to you under these Terms, and
                                any instrument purporting to do so shall be of
                                no force and effect.
                            </li>
                            <li>
                                We have no support obligations or liability
                                directly to you. Any support obligations or
                                liability you have will solely be those offered
                                to you by the Service Providers you engage with.
                            </li>
                            <li>
                                you may be subject to additional terms and
                                conditions imposed by the Service Providers you
                                engage with (the “Third-Party Terms”), which may
                                impose additional restrictions, or may provide
                                you with additional rights, in relation to your
                                use of our Services provided such restrictions
                                or rights are not inconsistent with these Terms.
                                You acknowledge that you are responsible for
                                ensuring that you are aware of and comply with
                                any Third-Party Terms applicable to you.
                            </li>
                            <li>
                                you acknowledge that we are not a party to, and
                                do not have any responsibility or obligations
                                resulting from, any agreements you enter with
                                Service Providers.
                            </li>
                            <li>
                                To the extent necessary to enable us to enforce
                                the provisions of these Terms respecting your
                                usage of our Services, we will be deemed a
                                third-party beneficiary of any agreements you
                                enter with Service Providers, and will have the
                                right to enforce the terms of any such agreement
                                against you as a third-party beneficiary
                                thereof.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>1.3 Electronic Communications</h3>
                        In connection with your access or use of our Services,
                        it may be necessary for us to communicate with you
                        electronically. Accordingly, you agree that we may send
                        commercial electronic messages to you in connection with
                        our Services. You may withdraw your consent to receive
                        commercial electronic messages at any time by contacting
                        Keel Mind at [contact] or by clicking on the unsubscribe
                        link in the messages. You acknowledge that our Services
                        may be limited if you do not agree to receive commercial
                        electronic messages.
                    </div>
                    <div>
                        <h3>1.4 Amendment</h3>
                        Keel Mind reserves the right to change any of these
                        Terms at any time and at their sole discretion. Any such
                        changes will be effective upon the posting of a new or
                        modified version of these Terms in a manner that is
                        accessible by you, including for example by posting the
                        new or modified version of these Terms on our Website.
                        No changes to these Terms of Use are valid or have any
                        effect unless agreed to by us in writing.
                    </div>
                    <div>
                        YOUR CONTINUED USE OF THE SERVICES FOLLOWING THE POSTING
                        OF ANY SUCH CHANGES WILL CONSTITUTE YOUR ACCEPTANCE OF
                        SUCH CHANGES. IF YOU DO NOT AGREE TO ANY SUCH CHANGES,
                        YOU MUST NOT CONTINUE TO ACCESS OR USE OUR SERVICES.
                    </div>
                    <h2>2. PERMITTED USES</h2>
                    <div>
                        You are only permitted to use our Services to (the
                        “Permitted Uses”):
                        <ul>
                            <li>
                                enable you to seek consultations with mental
                                health counselors, counselors in training, peer-
                                support, and any other practitioner within this
                                field;
                            </li>
                            <li>
                                enable you to communicate with your mental
                                health counselors, counselors in training, peer-
                                support, and any other practitioner within this
                                field by way of message or phone call;
                            </li>
                            <li>
                                to facilitate booking of private health sessions
                                with a mental health counselor, a counselor in
                                training, peer-support, and any other
                                practitioner within this field;
                            </li>
                            <li>
                                for either or both of Keel Mind and you to
                                exchange information;
                            </li>
                            <li>
                                to facilitate recordings of sessions for health
                                and wellness training purposes, and said
                                recordings are not accessible or considered a
                                part of medical/health records as it is used
                                strictly for health and wellness/ clinical
                                training purposes.
                            </li>
                        </ul>
                    </div>
                    <h2>3. REGISTRATION AND YOUR ACCOUNT</h2>
                    <div>
                        To use our Services, you must register for an account.
                        In order for you to register for an account, you must
                        provide accurate and complete registration information
                        and it is your responsibility to inform us of any
                        changes to that information by contacting us at{" "}
                        <a href="mailto:privacy@keel.digital">
                            privacy@keel.digital
                        </a>
                        . Keel Mind has the right to refuse, suspend or
                        terminate your access to our Services if we suspect at
                        any time that the information that you have provided is
                        not correct, current, or complete.
                    </div>
                    <div>
                        You are responsible for maintaining the confidentiality
                        of your account. You are not authorized to share your
                        account information, including your password, with any
                        other person. If your account is used by a third party
                        (whether or not authorized), the third party may be able
                        to use our Services without your knowledge and, as
                        between you and us, you will be fully liable for any
                        actions made with your accounts. You agree to change
                        your password frequently and to always keep it secure
                        and use MFA when and where possible.
                    </div>
                    <div>
                        You agree to immediately notify us of any unauthorized
                        use of your account or any other breach of security. In
                        no event will Keel Mind be liable for any loss or damage
                        resulting from the disclosure of your accounts. If you
                        believe someone has accessed any service using your
                        account without your authorization, it is your
                        responsibility to inform us and set up a new password or
                        contact us to delete your account.
                    </div>
                    <h2>4. INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP</h2>
                    <div>
                        Our Services and all the technology and content of or on
                        our Services (including without limitation all
                        information, reports, data, databases, graphics,
                        interfaces, web pages, text, files, software, code,
                        product names, company names, trademarks, logos, trade
                        names, any other intellectual property contained on our
                        Services), the manner in which such content is presented
                        or appears and all information relating thereto, and our
                        Service’s features and functionality (collectively, the
                        “Keel Mind IP”) are owned by us, our licensors, or other
                        providers of such Keel Mind IP, and are protected in all
                        forms by intellectual property laws, including without
                        limitation copyright, trademark, patent, trade secret,
                        industrial design, and any other proprietary rights.
                    </div>
                    <div>
                        We grant you a personal, revocable, non-transferable,
                        and non-exclusive license to access and read the Keel
                        Mind IP solely in connection with your access or use of
                        our Services for the Permitted Uses.
                    </div>
                    <div>
                        You agree that, except as explicitly authorized by us,
                        you will not:
                    </div>
                    <div>
                        <ol>
                            <li>
                                distribute the Keel Mind IP for any purpose,
                                including without limitation by compiling an
                                internal database, or by redistributing or
                                reproducing the Keel Mind IP by the press or
                                media or through any commercial network, cable,
                                or satellite system;
                            </li>
                            <li>
                                create derivative works of, reverse engineer,
                                decompile, disassemble, adapt, translate,
                                transmit, distribute, publish or republish,
                                download, store, arrange, modify, copy, bundle,
                                sell, sub-license, export, merge, transfer,
                                loan, rent, lease, assign, share, outsource,
                                host, make available to any person or otherwise
                                use, either directly or indirectly, the Keel
                                Mind IP in whole or in part, in any form or by
                                any means whatsoever, be they physical,
                                electronic, or otherwise; or
                            </li>
                            <li>
                                allow any third-party to access the Keel Mind
                                IP. All intellectual property and related
                                material (the "Intellectual Property") that is
                                developed or produced under this Agreement, will
                                be the property of the Vendor.
                            </li>
                        </ol>
                    </div>
                    <div>
                        All intellectual property and related material (the
                        "Intellectual Property") that is developed or produced
                        under this Agreement, will be the property of the
                        Vendor.
                    </div>
                    <h2>5. USE OF SERVICES</h2>
                    <div>
                        You agree that you will only access or use our Services
                        for lawful purposes and in accordance with these Terms,
                        and that you will not access or use our Services to:
                        <ul>
                            <li>
                                violate or promote the violation of any
                                government-imposed restriction or rule or of any
                                third party’s rights;
                            </li>
                            <li>
                                download, email, post, make available, provide
                                access, or otherwise transmit any material that
                                is unlawful, harmful, threatening, abusive,
                                harassing, tortious, defamatory, vulgar,
                                obscene, libelous, pornographic, hateful, or
                                racially, ethnically or otherwise objectionable,
                                including any materials which encourage conduct
                                that would constitute a criminal offence or give
                                rise to civil liability, infringe other’s
                                intellectual property or other rights, disclose
                                private or personal matters concerning any
                                person, or otherwise violate any applicable
                                local, provincial, national or international
                                law;
                            </li>
                            <li>
                                collect or store personal data about other
                                users, except in accordance with applicable law;
                            </li>
                            <li>
                                impersonate any person or entity, misrepresent
                                your affiliation with a person or entity, or do
                                any other thing or act that brings Keel Mind,
                                any other user of our Services, or any
                                third-party into disrepute or causes us
                                liability;
                            </li>
                            <li>
                                distribute viruses, malware, or any other
                                technologies that are malicious or that may harm
                                us, our Services, other users of our Services,
                                our affiliates, or any third-party, or in any
                                other way interfere or attempt to interfere with
                                the proper working of our Services;
                            </li>
                            <li>
                                reverse engineer, decompile, copy, modify,
                                distribute, transmit, license, sublicense,
                                display, revise, perform, transfer, sell, or
                                otherwise make available to any third-party, or
                                otherwise publish, deeplink, create derivative
                                works from or exploit in any way our Platform or
                                any content on our Services except as permitted
                                by the us under these Terms; or
                            </li>
                            <li>
                                harvest or otherwise collect, use, or disclose
                                (including using any robot, spider, or other
                                automatic device, process, or means) content on
                                our Services or personal information about any
                                other user of our Services.
                            </li>
                        </ul>
                    </div>
                    <h2>6. PRIVACY POLICY</h2>
                    <div>
                        Your use of our Services is governed by our privacy
                        policy, which can be found here. By accessing or using
                        our Services or otherwise submitting your personal
                        information to us, you consent to our collection, use,
                        and disclosure of your personal information in
                        accordance with the terms of our privacy policy. Our
                        privacy policy is incorporated into these Terms by
                        reference and form an integral part of these Terms.
                    </div>
                    <h2>7. TERMINATION</h2>
                    <div>
                        We may in our sole discretion for any or no reason, with
                        or without notice, and at any time: terminate these
                        Terms;
                        <ul>
                            <li>
                                limit, suspend, or terminate your access to or
                                use of our Services;
                            </li>
                            <li>
                                take technical and legal steps to prevent you
                                from accessing or using our Services; or
                            </li>
                            <li>
                                remove or otherwise modify any information or
                                content you have provided through our Services.
                            </li>
                            <li>
                                Any such termination or action taken by us under
                                this section 8 is in addition to and without
                                prejudice to such rights and remedies as may be
                                available to us, including injunctive and
                                equitable remedies. Any terms of these Terms
                                which are necessary to give effect to our rights
                                under these Terms or that contemplate survival
                                beyond termination will survive, except to the
                                extent not permitted by law.
                            </li>
                        </ul>
                    </div>
                    <h2>8. LIMITATION OF LIABILITY AND DISCLAIMERS</h2>
                    <div>
                        <h3>8.1 Limitation of Liability</h3>
                        EXCEPT TO THE EXTENT REQUIRED BY LAW, IN NO EVENT WILL
                        Keel Mind, ITS AFFILIATES, AGENTS, LICENSORS, SUPPLIERS,
                        OR ITS OR THEIR RESPECTIVE DIRECTORS, OFFICERS,
                        EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
                        LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE
                        LIABLE, WHETHER IN TORT, CONTRACT, OR OTHERWISE, FOR ANY
                        DIRECT, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
                        EXEMPLARY, AGGRAVATED, ECONOMIC, OR CONSEQUENTIAL
                        DAMAGES, HOWSOEVER CAUSED, INCLUDING BUT NOT LIMITED TO
                        DAMAGES FOR LOSS OF USE, LOST PROFITS, LOST GOODWILL OR
                        BUSINESS REPUTATION, LOST DATA, OR LOST SAVINGS, EVEN IF
                        Keel Mind OR ANY OF ITS LAWFUL AGENTS OR EMPLOYEES HAVE
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
                        CLAIM, RESULTING FROM YOUR USE OF OR INABILITY TO ACCESS
                        OR USE OUR SERVICES, INCLUDING WITHOUT LIMITATION: (A)
                        ANY CHANGES Keel Mind MAY MAKE TO OUR SERVICES, OR FOR
                        ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF
                        OUR SERVICES; (B) THE DELETION OF, CORRUPTION OF, OR
                        FAILURE TO SEND, DISPLAY, OR STORE ANY ADS, USER
                        GENERATED CONTENT, OR OTHER COMMUNICATIONS OR DATA
                        MAINTAINED OR TRANSMITTED BY OR THROUGH OUR SERVICES;
                        (C) YOUR FAILURE TO PROVIDE Keel Mind WITH ACCURATE
                        ACCOUNT DETAILS OR TO KEEP YOUR ACCOUNT DETAILS SECURE
                        AND CONFIDENTIAL, AS APPLICABLE. NOTWITHSTANDING THE
                        FOREGOING, IF Keel Mind IS FOUND TO BE LIABLE FOR ANY
                        REASON, THEN, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                        Keel Mind’S LIABILITY TO YOU OR ANY THIRD-PARTY IS
                        LIMITED TO $100 CAD.
                    </div>
                    <div>
                        <h3>8.2 AVAILABILITY, COMPLETENESS, AND QUALITY</h3>
                        YOU UNDERSTAND AND AGREE THAT OUR SERVICES ARE PROVIDED
                        ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
                        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                        INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE, OR
                        NON-INFRINGEMENT.
                    </div>
                    <div>
                        EXCEPT AS OTHERWISE EXPRESSLY REQUIRED BY APPLICABLE
                        LAW, Keel Mind MAKES NO REPRESENTATIONS, WARRANTIES,
                        CONDITIONS, OR OTHER TERMS (WHETHER EXPRESS OR IMPLIED)
                        IN RELATION TO THE PROVISION OF OUR SERVICES, INCLUDING
                        WITHOUT LIMITATION AS TO COMPLETENESS, SECURITY,
                        RELIABILITY, SUITABILITY, ACCURACY, AVAILABILITY, OR
                        CURRENCY OF OUR SERVICES, THAT OUR SERVICES WILL BE FREE
                        FROM BUGS, ERRORS, OR OMISSION, OR AS TO THE
                        SATISFACTORY QUALITY OR FITNESS OF OUR SERVICES FOR A
                        PARTICULAR PURPOSE. Keel Mind ASSUMES NO OBLIGATION TO
                        UPDATE OUR SERVICES. OUR SERVICES MAY BE CHANGED WITHOUT
                        NOTICE TO YOU. TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW, Keel Mind EXCLUDES ALL LIABILITY
                        (WHETHER ARISING IN CONTRACT, TORT, BREACH OF STATUTORY
                        DUTY, OR OTHERWISE), WHICH Keel Mind MAY OTHERWISE HAVE
                        TO YOU AS A RESULT OF ANY ERROR OR INACCURACIES IN OUR
                        SERVICES, THE UNAVAILABILITY OF OUR SERVICES FOR ANY
                        REASON, OR ANY REPRESENTATION OR STATEMENT MADE ON OR
                        THROUGH OUR SERVICES.
                    </div>
                    <div>
                        Keel Mind CANNOT AND DOES NOT CONFIRM THAT EACH USER IS
                        WHO THEY CLAIM TO BE. BECAUSE Keel Mind DOES NOT AND
                        CANNOT BE INVOLVED IN USER-TO-USER DEALINGS OR CONTROL
                        THE BEHAVIOUR OF USERS OF THE SERVICES, IF YOU HAVE A
                        DISPUTE WITH ONE OR MORE USERS, YOU RELEASE Keel Mind
                        (AND OUR AGENTS AND EMPLOYEES) FROM ALL CLAIMS, DEMANDS
                        AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
                        SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED,
                        ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
                        DISPUTES.
                    </div>
                    <div>
                        <h3>8.3 DOWNLOADS</h3>
                        Keel Mind cannot and does not guarantee or warrant that
                        files or data available for downloading from our
                        Services will be free of viruses or other destructive
                        code. You are solely and entirely responsible for your
                        use of our Services and your computer internet and data
                        security. To the fullest extent provided by law, Keel
                        Mind will not be liable for any loss or damage caused by
                        denial-of-service attack, distributed denial-of-service
                        attack, overloading, flooding, mailbombing or crashing,
                        viruses, trojan horses, worms, logic bombs, or other
                        technologically harmful materials that may infect your
                        computer equipment, computer programs, data, or other
                        proprietary material due to your use of our Services or
                        any services or items found or attained on or through
                        our Services or to your downloading of any material
                        posted on or through our Services, or on any website
                        linked to our Services.
                    </div>
                    <div>
                        <h3>8.4 THIRD-PARTY SITES</h3>Our Services may contain
                        links to third-party sites or may interoperate with
                        certain third-party sites or services. Keel Mind does
                        not assume responsibility for the accuracy or
                        appropriateness of, and has no control over, the
                        information, data, opinion, advice, or statements
                        contained at such sites, and makes no representations
                        about any such websites that may be accessed from our
                        Services. Where you access such sites, you acknowledge
                        and agree you are doing so at your own risk. Your use of
                        a third-party site may be governed by the terms and
                        conditions of such site.
                    </div>
                    <div>
                        In providing links to third-party sites, Keel Mind is in
                        no way acting as a publisher or disseminator of any
                        material contained on those sites and does not and does
                        not seek to monitor or control such sites.
                    </div>
                    <div>
                        A link to a third-party site does not mean and should
                        not be construed to mean that Keel Mind is affiliated or
                        associated with such third-party in any way. Keel Mind
                        does not recommend or endorse any material found on such
                        third-party sites. The mention of another party or its
                        product or service on our Services is not and should not
                        be construed as an endorsement of that party or its
                        product or service.
                    </div>
                    <div>
                        <h3>8.5 NO RELIANCE</h3>
                        Any reliance you may place on our Services is at your
                        own risk. Any content provided by Keel Mind on or
                        through our Services is provided for general information
                        purposes only. Such content does not constitute
                        technical, financial, medical, or legal advice, or any
                        other type of advice, and should not be relied on for
                        any purpose. You agree to apply your own judgment or
                        obtain specific or professional advice before taking, or
                        refraining from, any action or inaction on the basis of
                        our Services.
                    </div>
                    <h2>9. INDEMNIFICATION</h2>
                    <div>
                        To the maximum extent permitted by applicable law, you
                        agree at all times to indemnify, defend, and hold
                        harmless Keel Mind, its agents, affiliates, partners,
                        and its and their respective directors, officers,
                        employees, agents, service providers, contractors,
                        licensors, suppliers, successors, and assigns from and
                        against any claims, actions, proceedings, demands,
                        liabilities, damages, judgments, awards, losses, costs,
                        expenses, or fees (including legal and other fees and
                        disbursements) sustained, incurred, or paid by Keel
                        Mind, or arising out of or relating to your breach of
                        these Terms, your access or use of our Services, your
                        violation of any applicable law or regulation, or
                        violation of any third-party’s intellectual property or
                        other rights.
                    </div>
                    <h2>10. MISCELLANEOUS</h2>
                    <div>
                        <h3>10.1 FORCE MAJEURE</h3>
                        Keel Mind will have no liability to you for any breach
                        of these Terms caused by any event or circumstances
                        beyond Keel Mind’s reasonable control, including without
                        limitation strikes, lock-outs and other industrial
                        disputes, breakdown of systems or network access,
                        disease, flood, fire, explosion, or accident.
                    </div>
                    <div>
                        <h3>10.2 NO AGENCY</h3>
                        No agency, partnership, joint venture,
                        employee-employer, or franchiser-franchisee relationship
                        is intended or created by these Terms.
                    </div>
                    <div>
                        <h3>
                            10.3 GOVERNING LAW, JURISDICTION, AND ATTORNMENT
                        </h3>
                        These Terms are governed by and are to be construed in
                        accordance with the laws of the province of Ontario and
                        the laws of Canada applicable therein, without giving
                        effect to any choice of law provision, principle, or
                        rule, and notwithstanding your domicile, residence, or
                        physical location. For the purpose of all legal
                        proceedings, these Terms shall be deemed to have been
                        performed in the province of Ontario and the courts of
                        the province of Ontario shall have jurisdiction to
                        entertain any action arising under or out of these
                        Terms. You and us both agree to irrevocably attorn and
                        submit to the exclusive jurisdiction of the courts of
                        the province of Ontario. You further waive any and all
                        objections to the exercise of jurisdiction over you by
                        such courts and to the venue of such courts. You agree
                        to waive any right you may have to a trial by jury or to
                        commence or participate in any class action against us
                        related to our Services or these Terms.
                    </div>
                    <div>
                        <h3>10.4 WAIVER</h3>
                        No failure by us to exercise, or delay in exercising,
                        any right, remedy, power, or privilege arising from
                        these Terms operates, or may be construed, as a waiver
                        by us thereof. No single or partial exercise by us of
                        any right, remedy, power, or privilege hereunder
                        precludes any other or further exercise by us thereof or
                        the exercise of any other right, remedy, power, or
                        privilege.
                    </div>
                    <div>
                        <h3>10.5 SEVERABILITY</h3>
                        Any term of these Terms that is prohibited or
                        unenforceable in any jurisdiction shall, as to that
                        jurisdiction, be ineffective to the extent of such
                        prohibition or unenforceability and shall be severed
                        from the balance of these Terms, without affecting the
                        remaining terms of these Terms or affecting the validity
                        or enforceability of such terms in any other
                        jurisdiction. Nothing in these Terms shall operate to
                        prejudice any mandatory statutory requirement or your
                        statutory rights.
                    </div>
                    <div>
                        <h3>10.6 ENTIRE AGREEMENT</h3>
                        These Terms together with our privacy policy and the
                        other documents referenced herein contain the entire
                        understanding and agreement between you and us in
                        relation to your use of our Services, and supersede and
                        replace all prior and contemporaneous understandings,
                        agreements, representation, statement, or other
                        communication.
                    </div>
                    <div>
                        <h3>10.7 ASSIGNMENT</h3>
                        You may not assign, sublicense, or otherwise transfer
                        any of your rights and obligations in these Terms to any
                        other person. We may freely assign these Terms.
                    </div>
                    <div>
                        <h3>
                            10.8 Service Providers whose licenses are subsidized
                            by the Ministry of Colleges and Universities the
                            following definitions apply:
                        </h3>
                        End Users (Student Clients): A student registered at an
                        Ontario Postsecondary Institution who receives sessions
                        through the Platform. A student client cannot be a
                        family member of the student or a member of any other
                        community. A student client must be registered at a
                        postsecondary institution in Ontario at the time they
                        receive sessions Trainee: An eligible trainee is a
                        student registered in a mental health-related
                        postsecondary program or a student peer supporter who
                        utilizes the Platform to gain experience delivering
                        mental health support. A trainee must be registered
                        and/or completing requirements for a program at a
                        post-secondary institution to which the licence is
                        issued.
                    </div>
                    <div>
                        Session: A live interaction between a Trainee and a End
                        User (Student Client) through the platform with the
                        oversight of their corresponding Supervisor
                    </div>
                    <div>
                        <h3>10.9 GENERAL PROVISIONS</h3>
                        All rights not expressly granted by us herein are hereby
                        reserved. Headings are for reference purposes only and
                        in no way define, limit, construe, or describe the scope
                        or extent of such section.
                    </div>
                    <div>
                        <h3>10.10 CONTACT</h3>
                        Keel Mind may be contacted at{" "}
                        <a href="mailto:privacy@keel.digital">
                            privacy@keel.digital
                        </a>
                        .
                    </div>
                </Policy>
            </main>
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;
