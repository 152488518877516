import Header from "../../components/Header/Header";
import styles from "./LabsPage.module.css";
import Footer from "../../components/Footer/Footer";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import { useRef } from "react";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
// import ArrowAccordion from "../../components/ArrowAccordion/ArrowAccordion";
// import { labsFAQ } from "../../data/faq";
import WhiteTextSection from "../../components/WhiteTextSection/WhiteTextSection";
import SolutionComponent from "../../components/SolutionComponent/SolutionComponent";
import OverlayedImage from "../../components/OverlayedImage/OverlayedImage";
import Overlay from "../../components/OverlayedImage/Overlay";
import HeroImageCards from "../../components/HeroImageCards/HeroImageCards";
import usePageTitle from "../../hooks/usePageTitle";
// import keelLabIconSmall from "../assets";

function LabsPage() {
    usePageTitle("Lab");

    const whoWeAreRef = useRef(null);

    function handleLearnMoreClick() {
        whoWeAreRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    return (
        <div id={styles.labs}>
            <Header logoSrcLight="/images/keel-labs-logo-white.svg" />
            <main>
                <section id={styles.hero}>
                    <div id={styles.hero_card}>
                        <ArrowSubtitle
                            text={"Welcome to Keel Laboratory Services"}
                        />
                        <div id={styles.hero_card_top}>
                            <h1 className="main_h1">
                                Driving better outcomes in mental healthcare
                            </h1>
                        </div>
                        <div id={styles.hero_subtitle}>
                            <div>
                                Keel Laboratory Services plays an integral role
                                in building a healthcare system that is
                                accessible, connected, convenient and that
                                strives for the best patient outcomes. Our
                                clinical laboratory services provide much-needed
                                analytical data that aid healthcare
                                professionals in making well-informed and
                                personalized medical decisions for their
                                patients.
                            </div>
                        </div>
                        <button
                            id={styles.hero_learn_more}
                            onClick={handleLearnMoreClick}
                            className="orange_button"
                        >
                            Learn More
                            <img
                                src="/images/small-right-arrow-white.svg"
                                alt=""
                            />
                        </button>
                    </div>
                    <HeroImageCards>
                        <img
                            className={styles.align_left_small}
                            src="/images/labs-hero-image-1.png"
                            alt=""
                        />
                        <img
                            className={styles.align_right_small}
                            src="/images/labs-hero-image-2.png"
                            alt=""
                        />
                        <img src="/images/labs-hero-image-3.png" alt="" />
                    </HeroImageCards>
                </section>
                <WhiteTextSection
                    arrowText={"Who we are"}
                    reference={whoWeAreRef}
                >
                    Keel Laboratory Services is the only provincially licensed
                    and <span>ISO 15189 accredited laboratory</span> in Canada
                    performing hormone determinations in non-invasive, at-home
                    collected saliva samples.
                </WhiteTextSection>
                <div id={styles.labs_content}>
                    <section id={styles.what_we_do}>
                        <SectionHeading
                            arrowText={"What we do"}
                            heading={
                                "Optimizing mental healthcare with endocrinology testing"
                            }
                            darkArrowSubtitle={true}
                            mainStyle={{ maxWidth: 870 }}
                        >
                            Keel Laboratory Services offers tests like cortisol
                            and melatonin to assist mental healthcare
                            professionals in treating stress, anxiety,
                            depression, and sleep disorders. Our endocrinology
                            testing solutions provide valuable insights into
                            hormone health, improving patient outcomes.
                        </SectionHeading>
                        <div id={styles.what_we_do_content}>
                            <SolutionComponent
                                style={{ rowGap: 40, columnGap: 120 }}
                                heading={"The Keel Lab stress index"}
                                buttonText={"Book a test"}
                                buttonHref={"/contact-us"}
                                Graphic={() => (
                                    <OverlayedImage
                                        alt={
                                            "Woman with her face in her hands, with an overlay of a cortisol levels chart graphic showing sample data with decreasing cortisol levels over the span of a day."
                                        }
                                        src={
                                            "/images/labs-stress-base-image.png"
                                        }
                                    >
                                        <Overlay
                                            src={
                                                "/images/overlays/labs-stress-overlay-image-1.png"
                                            }
                                            maxWidth={200}
                                            bottom={-25}
                                            left={-40}
                                            blur={6}
                                        />
                                    </OverlayedImage>
                                )}
                            >
                                The Keel Lab stress index measures cortisol, the
                                body’s stress hormone. Cortisol production is a
                                normal response to stress, but prolonged high
                                levels can negatively affect your health. Low
                                cortisol throughout the day may indicate chronic
                                stress. Elevated cortisol levels over time can
                                lead to muscle wasting, skin thinning, bone
                                loss, immune suppression, and symptoms like
                                sleep disturbances, fatigue, anxiety, and weight
                                gain. Chronic stress and high cortisol levels
                                contribute to premature aging and illness.
                                Monitoring cortisol levels can help regulate
                                stress, improving overall well-being and mental
                                fitness.
                            </SolutionComponent>
                            <SolutionComponent
                                reversed={true}
                                style={{ rowGap: 40, columnGap: 120 }}
                                heading={"The Keel Lab sleep index"}
                                buttonText={"Book a test"}
                                buttonHref={"/contact-us"}
                                Graphic={() => (
                                    <OverlayedImage
                                        src={
                                            "/images/labs-sleep-base-image.png"
                                        }
                                        alt={
                                            "Man sleeping in a bed, with an overlay of a morning sleep index measure showing high results."
                                        }
                                    >
                                        <Overlay
                                            src={
                                                "/images/overlays/labs-sleep-overlay-image-1.png"
                                            }
                                            maxWidth={310}
                                            blur={6}
                                            right={-50}
                                            bottom={-35}
                                        />
                                    </OverlayedImage>
                                )}
                            >
                                The Vector Sleep Index measures melatonin, the
                                hormone that regulates sleep. High melatonin
                                levels are linked to anxiety, stress, and
                                depression, while low levels are associated with
                                insomnia and mood swings. Elevated melatonin,
                                especially in the morning, can cause daytime
                                drowsiness, low body temperature, dizziness, and
                                reduced muscle tone, impacting sleep quality and
                                duration. Adequate melatonin levels promote
                                deeper, more restorative sleep, improving
                                overall sleep quality and reducing nighttime
                                awakenings.
                            </SolutionComponent>
                        </div>
                    </section>

                    <section id={styles.work_with}>
                        <div
                            className="big_dark_card"
                            id={styles.evidence_card}
                        >
                            <SectionHeading
                                arrowText={"Who we work with"}
                                heading={
                                    "Collaboration is the cornerstone of any healthy healthcare system"
                                }
                                headingStyle={{
                                    color: "white",
                                    marginBottom: 0,
                                }}
                                textStyle={{
                                    color: "white",
                                    lineHeight: "20px",
                                    fontWeight: 300,
                                }}
                            >
                                Keel Laboratory Services is extremely proud and
                                excited to be partnering with major laboratory
                                service partners, including LifeLabs and
                                In-Common Laboratories (ICL). Working together
                                allows us to bring forward a unified effort and
                                increase our positive impact on patient care.
                            </SectionHeading>
                            <div id={styles.work_with_logos_container}>
                                <img
                                    src="/images/lifelabs_logo.png"
                                    alt="LifeLabs logo"
                                />
                                <img
                                    src="/images/fluids_iq_logo.png"
                                    alt="Fluids IQ logo"
                                />
                                <img
                                    src="/images/icl_logo.png"
                                    alt="In-Common Laboratories logo"
                                />
                            </div>
                        </div>
                    </section>
                    {/* <section id={styles.faq_section} className={styles.full}>
                        <SectionHeading
                            arrowText={"FAQ"}
                            heading={"Answers to common questions"}
                            darkArrowSubtitle={true}
                        />
                        <ArrowAccordion data={labsFAQ} />
                    </section> */}
                </div>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default LabsPage;
