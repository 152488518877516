import BasicPage from "../../components/BasicPage/BasicPage";
import usePageTitle from "../../hooks/usePageTitle";
import styles from "./InformedConsentPage.module.css";

function InformedConsentPage() {
    usePageTitle("Informed Consent");

    return (
        <BasicPage>
            <h1 className="main_h1 center">Informed Consent</h1>
            <div className={styles.content}>
                <p>
                    Welcome to Keel Mind! We provide a platform-as-a-service
                    solution that allows third-party services providers to
                    provide higher quality online services.
                </p>
                <p>
                    To access or use our Services, you must agree to be bound by
                    our Terms of Use located here:{" "}
                    <a href="/terms-of-use">Terms of Use</a> along with the
                    following Informed Consent.
                </p>
                <p>
                    This Informed Consent for telehealth & wellness contains
                    important information.
                </p>
                <p>
                    Please read it carefully with your acceptance and use of the
                    service, it will represent an agreement between you and your
                    Third Party Service Provider.
                </p>
                <h2>General Information:</h2>
                <h3>Benefits/Risks of Telehealth and wellness</h3>
                <ul>
                    <li>
                        Telehealth and wellness refers to providing health and
                        wellness services remotely via video or telephone,
                        typically for the benefits of continuity of care and
                        convenience. Telehealth and wellness, however, requires
                        technical competence. Here are some risks and
                        differences between in-person health and wellness
                        consultations and telehealth and wellness:
                    </li>
                </ul>
                <h3>Risks to confidentiality</h3>
                <ul>
                    <li>
                        Because telehealth and wellness sessions take place
                        outside of the Third Party Service Provider's private
                        office, there is potential for other people to overhear
                        sessions. Keel Mind® on behalf of your Third Party
                        Service Provider will take reasonable steps to ensure
                        your privacy as it relates to our service. You should do
                        the same, making sure to be in an area where other
                        people cannot overhear the session.
                    </li>
                </ul>
                <h3>Issues related to technology</h3>
                <ul>
                    <li>
                        There are many ways that disruptions can arise with
                        technology, such as it stopping working during a session
                        or other people potentially getting access to our
                        conversation.
                    </li>
                </ul>
                <h3>Crisis management/intervention</h3>
                <ul>
                    <li>
                        Telehealth and wellness is not typically used for times
                        of crisis and can be harder to manage than in-person.
                    </li>
                </ul>
                <h3>Efficacy</h3>
                <ul>
                    <li>
                        Most research shows that telehealth and wellness
                        services can be as effective as in-person health and
                        wellness services, but you should know that there is
                        debate about whether or not something is lost by not
                        being in the same room, such as a Third Party Service
                        Provider's ability to fully read non-verbal information.
                    </li>
                </ul>
                <h3>Location During Sessions</h3>
                <ul>
                    <li>
                        All clients must be in an appropriate and private
                        setting for telehealth and wellness sessions. By signing
                        this, you agree that no sessions will be conducted while
                        you are out-of-state or province from where your
                        provider resides. This restriction is due to licensure
                        restrictions unless otherwise stipulated.
                    </li>
                </ul>
                <h3>Electronic Communications</h3>
                <ul>
                    <li>
                        Telehealth and wellness sessions allow your Third Party
                        Service Provider to diagnose, consult, treat and educate
                        using interactive audio, video or data communication
                        regarding your treatment. I hereby consent to
                        participating in telehealth and wellness sessions via
                        telephone or the internet. I am also aware that sessions
                        may be recorded for training purposes or to facilitate
                        collaborative care and give your consent. A supervisor,
                        from time to time, of equal or greater competence may
                        join sessions from behind a “digital one-way mirror” to
                        ensure high-level, responsible care. I understand that a
                        Third Party Service Provider can include a supervisor as
                        well.
                    </li>
                </ul>
                <h3>Communication between Sessions</h3>
                <ul>
                    <li>
                        As with in-person sessions, treatment is most effective
                        when clinical discussions occur at your regularly
                        scheduled sessions. We completely realize and empathize
                        that a crisis may arise, and if so, please contact your
                        family physician or the nearest emergency room.
                    </li>
                    <li>
                        You can also contact the 24-hour National Suicide
                        Prevention Lifeline at: 1-800-273-TALK (8255), or the
                        24-hour Crisis Text Line can be contacted by texting
                        “CONNECT” to 741741 in the United States. If in Canada,
                        The new Canada Suicide Prevention Service (CSPS), by
                        Crisis Services Canada, enables callers anywhere in
                        Canada to access crisis support by phone, in French or
                        English: toll-free 1-833-456-4566 Available 24/7. Crisis
                        Text
                    </li>
                    <li>
                        Line (Powered by Kids Help Phone) Canada Wide free, 24/7
                        texting service is accessible immediately to youth
                        anywhere in Canada by texting TALK to 686868 to reach an
                        English speaking Crisis Responder and/or text CONNECT to
                        686868 to reach a French-speaking Crisis Responder on
                        any text/SMS enabled cell phone.
                    </li>
                    <li>
                        KidsHelpPhone Ages 20 Years and Under in Canada
                        1-800-668-6868 (Online or on the Phone)
                    </li>
                    <li>
                        First Nations and Inuit Hope for Wellness 24/7 Help Line
                        1-855-242-3310
                    </li>
                    <li>
                        Canadian Indian Residential Schools Crisis Line
                        1-866-925-4419
                    </li>
                    <li>Trans LifeLine – All Ages 1-877-330-6366</li>
                </ul>
                <h3>Confidentiality</h3>
                <ul>
                    <li>
                        You are aware that your Third Party Service Provider and
                        their provider Keel Mind® have taken precautions to
                        avoid loss of confidentiality during electronic
                        transmission of confidential information. The extent of
                        confidentiality and its exceptions, as outlined in any
                        Informed Consent document that you have been given by
                        your Third Party Service Provider(s) still apply.
                        However, the nature of electronic communications
                        technologies is such that Keel Mind® must warn you that
                        although we have taken the necessary precautions and
                        steps to protect your data, we cannot guarantee that all
                        communications will be kept confidential or that other
                        people may not gain access to your communications. You
                        should also take reasonable steps to ensure the security
                        of your communications (for example, only using secure
                        networks for telehealth and wellness sessions and having
                        passwords to protect the device you use for your
                        sessions).
                    </li>
                </ul>
                <h3>Data Ownership Within the Keel Mind® Solution</h3>
                <ul>
                    <li>
                        The Keel Mind platform is a PaaS (platform as a service)
                        solution where Keel Mind operates the platform as a
                        Service for the Third Party Service Provider who is a
                        Keel Mind customer. With this in mind, all customer data
                        is owned by the Third Party Service Provider. Keel Mind
                        is a data custodian of this customer data, and we
                        protect this data on behalf of our Third Party Service
                        Provider.
                    </li>
                </ul>
                <h3>Your Rights</h3>
                <ul>
                    <li>
                        You have a right to confidentiality with telehealth and
                        wellness sessions under the same laws that protect the
                        confidentiality of your medical information for
                        in-person sessions. Any information disclosed by you
                        during the course of your service, therefore, is
                        generally confidential. There are, by law, exceptions to
                        confidentiality, including mandatory reporting of child,
                        elder, and dependent adult abuse and any threats of
                        violence you may make towards a reasonably identifiable
                        person. You also understand that if you are in such a
                        mental or emotional condition to be a danger to yourself
                        or others, your Third Party Service Provider(s) has the
                        right to break confidentiality to prevent the threatened
                        danger. Further, you understand that the dissemination
                        of any personally identifiable images or information
                        from the telehealth and wellness interaction to any
                        other entities other than your Third Party Service
                        Provider(s) shall not occur without your written
                        consent.
                    </li>
                </ul>
                <h3>Technology Disruptions</h3>
                <ul>
                    <li>
                        If you are having an emergency and there is a technical
                        problem, call 911, the 24-hour National Suicide
                        Prevention Lifeline (1-800-273-TALK/8255), or go to your
                        nearest emergency room. In Canada, contact
                        1-833-456-4566 or text TALK to 686868. If the session is
                        interrupted under non-emergency circumstances, your
                        provider will attempt to recontact you via the platform
                        where we were disrupted. If you do not receive a call
                        back within a couple of minutes, then reach out to your
                        provider through the platform or contact
                        support@geta-head.com.
                    </li>
                </ul>
                <h3>Records</h3>
                <ul>
                    <li>
                        You hereby consent that you understand your Third Party
                        Service Provider(s) will maintain a medical record of
                        your session and any derivative data, analytics or
                        summaries in the same way they maintain records of
                        in-person sessions. This includes demographic
                        information, specific interactions, and progress notes.
                        You hereby consent that although sessions may be
                        recorded from time-to-time, that because it will be
                        strictly used for health and wellness/clinical training,
                        and is not a part of your medical records and that you
                        give up right to access that recording as it can pose a
                        potential threat to you and your Third Party Service
                        Provider(s) if made public. You understand these
                        recordings are securely kept in a HIPAA/PIPEDA/PHIPA
                        compliant environment depending on your location and are
                        disposed of in a reasonable period of time as determined
                        by your Third Party Service Provider.
                    </li>
                </ul>
                <h3>Informed Consent</h3>
                <ul>
                    <li>
                        This agreement is intended as a supplement to the
                        general informed consent used at the outset of service
                        and does not amend any of the terms of a previous
                        agreement or agreement with your Third Party Service
                        Provider. You understand that you can withdraw your
                        consent to telehealth and wellness communications by
                        providing written notification to your Third Party
                        Service Provider. Clicking the box below indicates that
                        you have read this Agreement and agree to its terms.
                    </li>
                </ul>
            </div>
        </BasicPage>
    );
}

export default InformedConsentPage;
