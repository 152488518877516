export const mindTestimonials = [
    {
        text: '"It is my belief that the potential benefits and improvements this platform can bring to the mental health care services within our community are substantial. Over the period of its use, I have been pleased with the platform\'s performance and the tangible benefits it has delivered to our community members."',
        name: "Billy Joe Strang",
        title: "CEO Pikangikum Health Authority",
    },
    {
        text: '"I have been working clinically with Keel Mind for 4 years. This platform has undeniably increased my capacity and efficiency in conducting my clinical practice and program of psychotherapy research. I am very excited about the human delivered-AI enhanced model of integrating technology into psychotherapy in a manner that is safe and responsible, yet innovative and forward-thinking. Keel has also empowered me to expand my clinical practice geographically, where we have been working with Northern and Indigenous communities. I am humbled and honoured to have been invited into these spaces, where we collaboratively provide culturally sensitive care in regions that have limited resources when it comes to mental health support. I presently have a number of projects on the go with Keel, and I am excited to see how we can continue to support clients in innovative ways."',
        name: "Dr. Dillon Browne, C.Psych",
        title: "Canada Research Chair in Child and Family Clinical Psychology. Founder & Director, FamilyPsycle Institute. University of Waterloo",
        width: 794,
    },
    {
        text: '"In our ongoing mission to enhance technology delivery within Indigenous communities, the Keel Mind platform is an exceptional tool. Its ability to improve and streamline care delivery is evident, and the value it has already offered in community is significant."',
        name: "Nicole Brown",
        title: "COO FSet Inc.",
    },
    {
        text: '"As a Nurse Practitioner for Pikangikum Health Authority, I am thrilled to express my hearty endorsement for the Keel Mind platform. Over the past months, I have had the privilege to implement and utilize KM as an integral part of our service delivery system, and I am genuinely impressed with its efficacy and efficiency."',
        name: "Brian LePage",
        title: "Nurse Practitioner - Primary Health Care, Psychotherapist Pikangikum Health Authority",
    },
    {
        text: "Our experiences with the KM platform have confirmed its potential for scalability. I am therefore eager to expand its use across more areas within our community. The platform's adaptability, combined with its potential for customization to cater to the unique needs of each program, make it an ideal tool for our community's healthcare initiatives.\"",
        name: "Joe Barnes",
        title: "Senior Advisor Kenora's Chief Advisor",
    },
    {
        text: '"I am thankful for the help I have been able to get in improving my mental health and wellness"',
        name: "Anonymous Student",
        title: "Keel Mind Platform User",
    },
    {
        text: '"It would be great if other people with mental health issues get to benefit from the platform."',
        name: "Anonymous Student",
        title: "Keel Mind Platform User",
    },
];
