import { useState } from "react";
import styles from "./HamburgerDropdown.module.css";

function HamburgerDropdown({ text, children, id, style, className }) {
    const [showDropdown, setShowDropdown] = useState(false);

    function handleClick() {
        setShowDropdown((prev) => !prev);
    }

    return (
        <div className={styles.hamburger_dropdown_container}>
            <button
                id={id}
                className={[styles.hamburger_dropdown, className].join(" ")}
                style={style}
                onClick={handleClick}
            >
                {text}

                <svg
                    className={styles.arrow}
                    width="28"
                    height="16"
                    viewBox="0 0 28 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 2L14 14L26 2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <div
                className={[
                    styles.children_container,
                    showDropdown ? styles.show : undefined,
                ].join(" ")}
            >
                {children}
            </div>
        </div>
    );
}

export default HamburgerDropdown;
