import {
    academiaOverlays,
    firstRespondersOverlays,
    indigenousOverlays,
    k12Overlays,
} from "../components/OverlayedImage/ExportedOverlays";

export const ourApproach = {
    "First responders": {
        baseImg: "/images/first-responders-vertical-base-image.png",
        overlays: firstRespondersOverlays,
        alt: "",
        description: `Keel Mind supports first responders navigating the trauma of urgent, unpredictable, and often life-threatening work. But it's more than a platform — it's a movement redefining first responder wellness by blending the power of human connection with modern innovation. We meet first responders wherever they are in their journey — offering self-assessments for personal insights, Keel Reels to share the "why" behind peer support, and a wellness hub with expert-driven resources. With direct access to certified peer supporters, first responders receive real, personalized support — whenever they need it.`,
    },
    Indigenous: {
        baseImg: "/images/indigenous-vertical-base-image.png",
        alt: "",
        overlays: indigenousOverlays,
        description: `Indigenous communities are typically located in remote areas with limited access to mental health resources. Incidence of addiction, depression and suicide are 6 times higher in these communities compared to other communities in North America. The Keel Mind platform provides these communities with culturally informed mental health tools that honor the history and traditions of Native Peoples. Keel Mind connects these communities with academic institutions and care providers, bridging the gaps in mental health support, ensuring care is delivered with both respect and integrity.`,
    },
    Academia: {
        baseImg: "/images/academia-vertical-base-image.png",
        alt: "",
        overlays: academiaOverlays,
        description: `Mental health professionals of tomorrow are using Keel Mind’s AI-driven platform to train and deliver care in a new and more efficient way. Currently offered at over 40 North American post-secondary institutions, Keel Mind uses AI to support therapists-in-training and their supervisors with groundbreaking decision tools. This innovative platform equips students with the tools to deliver care more efficiently while enhancing their educational experience. By integrating culturally informed, data-driven solutions, Keel Mind helps institutions graduate skilled professionals who are better prepared to address mental health challenges and support student well-being more effectively.`,
    },
    "K-12 Education": {
        baseImg: "/images/k12-vertical-base-image.png",
        alt: "",
        overlays: k12Overlays,
        description:
            "Keel Thrive is the leading communimetric tool deployed in K-12 schools to identify students at risk. Keel supports schools by identifying students' mental health needs through online well-being check-ins and stakeholder surveys. Students, teachers, and behavioral insights are combined and run through an AI engine to flag risks, prioritize resources, and guide interventions. The output is all accessible via a comprehensive dashboard.",
    },
};
