export const labFAQ = [
    {
        q: "Why saliva? Meaning, what’s the difference between the values I see from a regular blood test and those from a saliva test?",
        a: "First, saliva sampling is very convenient because it is non-invasive which allows multiple samples to be collected throughout the day at home, or at work. Second, blood testing provides a measurement of the total amount of hormone in the body, while saliva testing only measures the quantity of the free (i.e. biologically active fraction) of the circulating hormone which correlates best with functional activity.",
    },
    {
        q: "If I’m collecting this at home and sending it back via courier, how stable is my saliva sample in transit?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to fast before providing my saliva sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to stop taking all my medication before providing my sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "My doctor has never heard of this type of test. Now what do I do?",
        a: "PLACEHOLDER",
    },
];

export const mindFAQ = [
    {
        q: "How do I sign up?",
        a: "We work directly with agencies to provide access keys. Keys are not provided to independent parties and must be obtained by an overseeing agency. If you are interested in using our platform, please fill out this contact form and share this with your agencies wellness department.",
    },
    {
        q: "What should I do if I encounter a technical issue?",
        a: "Try refreshing, clearing your cache, and/or restarting your device. If that doesn’t resolve the issue, please feel free to reach out to our support team at <a href='mailto:support@keelmind.com'>Keel Mind Support</a>",
    },
    {
        q: "How does Keel Mind protect my privacy?",
        a: "We built this platform from a security-first perspective. We are HIPPA and PIPEDA compliant and are Soc II Type 2 grade security ",
    },
    {
        q: "How does Keel Mind handle my personal information?",
        a: "Securely. We are HIPPA and PIPEDA compliant, our browsing encryption system (SSL) follows best practices and is rated A+, data stores are encrypted at rest and all data is encrypted during transit, all messages between individuals on the platform are 256 bit encryption and the servers are hosted in a purpose built “A Grade” facility.",
    },
];
