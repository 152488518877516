import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import styles from "./SupportDirectoryTile.module.css";
import { Link } from "react-router-dom";

function SupportDirectoryTile({ text, href, target, rel, className, style }) {
    return (
        <Link
            className={[className, styles.tile].join(" ")}
            style={style}
            to={href}
            target={target}
            rel={rel}
        >
            <div>{text}</div>
            <ArrowSubtitle text={""} />
        </Link>
    );
}

export default SupportDirectoryTile;
