import { useEffect } from "react";

function usePageTitle(title) {
    useEffect(() => {
        document.title = title + " | Keel Digital Solutions";
    }, [title]);

    return null;
}

export default usePageTitle;
