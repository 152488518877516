import styles from "./SupportDirectoryPage.module.css";
import BasicPage from "../../components/BasicPage/BasicPage";
import usePageTitle from "../../hooks/usePageTitle";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import { supportDirectoryLinks } from "../../data/navigation";
import SupportDirectoryTile from "../../components/SupportDirectoryTile/SupportDirectoryTile";

function SupportDirectoryPage() {
    usePageTitle("Support Directory");

    return (
        <BasicPage mainClassName={styles.main}>
            <div id={styles.heading}>
                <ArrowSubtitle text={"Keel Support"} dark={true} />
                <h1 className="main_h1 center">Support Directory</h1>
            </div>
            <div id={styles.tiles_container}>
                {supportDirectoryLinks.map(SupportDirectoryTile)}
            </div>
        </BasicPage>
    );
}

export default SupportDirectoryPage;
