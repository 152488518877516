import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import BasicPage from "../../components/BasicPage/BasicPage";
import usePageTitle from "../../hooks/usePageTitle";
import styles from "./CrisisSupportPage.module.css";

function CrisisSupportPage() {
    usePageTitle("Crisis Support");
    return (
        <BasicPage>
            <div id={styles.content}>
                <div id={styles.heading}>
                    <ArrowSubtitle text={"Immediate Assistance"} dark={true} />
                    <h1 className="main_h1  center">Crisis Support</h1>
                </div>
                <div id={styles.top}>
                    <p>
                        Life can be really hard some times. If you are
                        experiencing suicidal thoughts, you are not alone. Help
                        is just text or phone call away.
                    </p>
                    <p>
                        <h2>Emergency 911</h2>
                        If your life is in danger or someone you know, do you
                        hesitate to DIAL EMERGENCY NOW (often 911 in your area).
                        Click{" "}
                        <a href="https://thelifelinecanada.ca/suicide-prevention-crisis-centre-contact-information/crisis-centres/">
                            here
                        </a>{" "}
                        to find emergency services in your area.
                    </p>
                    <p>
                        <h2>Talk Suicide - Call or Text 9-8-8</h2>
                        Connect to a crisis responder for help in English or
                        French without judgment. Call 1-833-456-4566 toll-free,
                        any time – or text 45645 from 4 p.m. to midnight ET.
                    </p>
                </div>
                <div id={styles.bottom}>
                    <div id={styles.left}>
                        <h2>Canada Wide</h2>
                        <p>
                            If you’re having thoughts of suicide, or are worried
                            about someone else,{" "}
                            <a href="https://talksuicide.ca/">Talk Suicide</a>{" "}
                            is here to listen. Connect to a crisis responder for
                            help in English or French without judgment. Call
                            1-833-456-4566 toll-free, any time – or text 45645
                            from 4 p.m. to midnight ET.
                        </p>
                        <p>
                            <a href="https://kidshelpphone.ca/need-help-now-text-us/">
                                Crisis Text Line
                            </a>{" "}
                            (Powered by Kids Help Phone) Canada Wide free, 24/7
                            texting service is accessible immediately to youth
                            anywhere in Canada by texting TALK to 686868 to
                            reach an English speaking Crisis Responder and TEXTO
                            to 686868 to reach a French-speaking Crisis
                            Responder on any text/SMS enabled cell phone.
                        </p>
                        <p>
                            <a href="https://kidshelpphone.ca/">
                                KidsHelpPhone
                            </a>{" "}
                            Ages 20 Years and Under in Canada 1-800-668-6868
                            (Online or on the Phone)
                        </p>
                        <p>
                            First Nations and Inuit Hope for Wellness 24/7 Help
                            Line 1-855-242-3310
                        </p>
                        <p>
                            Canadian Indian Residential Schools Crisis Line
                            1-866-925-4419
                        </p>
                        <p>Trans LifeLine – All Ages 1-877-330-6366</p>
                    </div>
                    <div id={styles.right}>
                        <h2>U.S. Wide</h2>
                        <p>
                            United States Suicide Prevention Lifelines are
                            available 24/7 – so make use of them if your loved
                            one needs to talk with someone urgently.
                        </p>
                        <p>
                            Call National Hope Helpline at 1-800-SUICIDE
                            (1-800-784-2433) or the National Suicide Prevention
                            Lifeline at 1-800-273-TALK (1-800-273-8255) or in
                            Spanish, 1-888-628-9454.
                        </p>
                    </div>
                </div>
            </div>
        </BasicPage>
    );
}

export default CrisisSupportPage;
