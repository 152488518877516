export const dialInOptions = {
    dev: [{ name: "Toronto", number: "+1 (647) 492-8314" }],
    prod: [
        { name: "Toronto", number: "+1 (647) 578-7799" },
        { name: "Ottawa", number: "+1 (343) 700-5373" },
        { name: "London", number: "+1 (226) 270-5909" },
        { name: "Barrie", number: "+1 (249) 444-2515" },
        { name: "Guelph", number: "+1 (226) 770-4080" },
        { name: "Hamilton", number: "+1 (289) 768-8277" },
    ],
};
