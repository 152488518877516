import Header from "../../components/Header/Header";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import styles from "./LeadershipPage.module.css";
import { ourAdvisors, ourLeadership } from "../../data/people";
import AdvisorCard from "../../components/AdvisorCard/AdvisorCard";
import Footer from "../../components/Footer/Footer";
import LeaderCard from "../../components/LeaderCard/LeaderCard";
import VideoHero from "../../components/VideoHero/VideoHero";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import { useCallback, useEffect, useRef, useState } from "react";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import useScrollPosition from "../../hooks/useScrollPosition";
import useWindowSize from "../../hooks/useWindowSize";
import usePageTitle from "../../hooks/usePageTitle";

function LeadershipPage() {
    const [focusedCard, setFocusedCard] = useState("0");
    const leadersContainerRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const scrollPosition = useScrollPosition();
    const windowSize = useWindowSize();

    const overflowWrapperTopMargin = 100;
    const heroTopTopMargin = 160;
    const overflowWrapperBottomPadding = 140;
    const heroCardWidth = 360;

    const getCardHeight = useCallback(() => {
        return leadersContainerRef.current.getBoundingClientRect().height;
    }, []);

    const getStickyTop = useCallback(() => {
        const top = Math.max((windowSize.height - getCardHeight()) / 2, 0);
        // console.log(cardHeight);
        return top;
    }, [windowSize, getCardHeight]);

    const getTopOffset = useCallback(() => {
        return (
            heroTopTopMargin +
            document.getElementById(styles.hero_top).getBoundingClientRect()
                .height +
            Math.max(0, overflowWrapperTopMargin - getStickyTop())
        );
    }, [getStickyTop]);

    const setStickyTop = useCallback(() => {
        const top = getStickyTop();

        leadersContainerRef.current.parentNode.style.top = top + "px";
    }, [getStickyTop]);

    const getScrollContainerHeight = useCallback(() => {
        return (
            getFullLeadersWidth() +
            getTopOffset() +
            Math.max(
                0,
                overflowWrapperBottomPadding +
                    getCardHeight() -
                    windowSize.height
            )
        );
    }, [getTopOffset, getCardHeight, windowSize]);

    const setScrollContainerHeight = useCallback(() => {
        scrollContainerRef.current.style.setProperty(
            "--extra-scroll-height",
            `${getScrollContainerHeight()}px`
        );
    }, [getScrollContainerHeight]);

    function getFullLeadersWidth() {
        const width =
            (ourLeadership.length - 1) * heroCardWidth +
            (ourLeadership.length - 1) * 0;

        return width;
    }

    const findClosestToMiddle = useCallback(() => {
        const middleOfScreen = windowSize.width / 2;
        const children = leadersContainerRef.current.children;

        let closestElement = null;
        let smallestDistance = Infinity;

        for (const child of children) {
            const rect = child.getBoundingClientRect();
            const childCenter = rect.left + rect.width / 2;
            const distanceToMiddle = Math.abs(childCenter - middleOfScreen);

            // Stop searching if distances are increasing
            if (distanceToMiddle > smallestDistance) break;

            smallestDistance = distanceToMiddle;
            closestElement = child;
        }

        return closestElement;
    }, [windowSize]);

    const handleScroll = useCallback(() => {
        const leadersTranslationPosition = Math.min(
            Math.max(0, scrollPosition - getTopOffset()),
            getFullLeadersWidth()
        );
        leadersContainerRef.current.style.setProperty(
            "--translation",
            `${leadersTranslationPosition}px`
        );

        const backgroundHeroVideo = document.getElementById(
            styles.background_hero_video
        );
        const videoTop = Math.min(
            0,
            getScrollContainerHeight() - scrollPosition
        );

        backgroundHeroVideo.style.setProperty("--top", videoTop + "px");

        setFocusedCard(findClosestToMiddle().dataset.index);
    }, [
        scrollPosition,
        findClosestToMiddle,
        getTopOffset,
        getScrollContainerHeight,
    ]);

    useEffect(() => {
        setFocusedCard(findClosestToMiddle().dataset.index);
    }, [findClosestToMiddle]);

    useEffect(() => {
        setScrollContainerHeight();
        setStickyTop();
    }, [setScrollContainerHeight, setStickyTop]);

    useEffect(() => {
        handleScroll();
    }, [handleScroll, scrollPosition]);

    usePageTitle("Company");

    return (
        <div id={styles.leadership}>
            <Header />
            <main id={styles.main}>
                <div ref={scrollContainerRef} id={styles.scroll_container}>
                    <VideoHero id={styles.background_hero_video} />
                    {/* <div id={styles.hero}> */}
                    <div id={styles.hero_top}>
                        <div id={styles.hero_text}>
                            <ArrowSubtitle text={"Meet the leadership team"} />
                            <h1 className="main_h1">
                                Keel Digital Solutions' executive has{" "}
                                <em>diverse expertise</em> across a variety of
                                industries
                            </h1>
                            <p>
                                Including technology, banking, professional
                                sports and health care.
                            </p>
                        </div>
                    </div>
                    <div id={styles.leaders_overflow_wrapper}>
                        <div
                            ref={leadersContainerRef}
                            id={styles.leaders_container}
                        >
                            {ourLeadership.map((l, index) => (
                                <LeaderCard
                                    key={l.name}
                                    index={`${index}`}
                                    focusedCard={focusedCard}
                                    setFocusedCard={setFocusedCard}
                                    findClosestToMiddle={findClosestToMiddle}
                                    {...l}
                                />
                            ))}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                <section
                    className={styles.advisors_section}
                    id={styles.board_section}
                >
                    <SectionHeading
                        heading={"Board of directors"}
                    ></SectionHeading>
                    <div className={styles.advisors_container}>
                        {ourAdvisors
                            .filter((a) => a.role === "Board member")
                            .map((a) => (
                                <AdvisorCard key={a.name} {...a} />
                            ))}
                    </div>
                </section>
                <section className={styles.advisors_section}>
                    <SectionHeading heading={"Advisors"}></SectionHeading>
                    <div className={styles.advisors_container}>
                        {ourAdvisors
                            .filter((a) => a.role === "Advisor")
                            .map((a) => (
                                <AdvisorCard key={a.name} {...a} />
                            ))}
                    </div>
                </section>
                <section id={styles.quote_section}>
                    <div
                        className={[styles.quote_card, "big_dark_card"].join(
                            " "
                        )}
                    >
                        <div className={styles.quote_text}>
                            <img
                                className={[
                                    styles.quotation_mark_image,
                                    styles.open_quote,
                                ].join(" ")}
                                src="/images/open-quote.svg"
                                alt="Open quote"
                            />
                            Let’s embrace a future where technology not only
                            advances our capabilities but also supports our
                            mental wellness.
                            <img
                                className={[
                                    styles.quotation_mark_image,
                                    styles.close_quote,
                                ].join(" ")}
                                src="/images/close-quote.svg"
                                alt="Close quote"
                            />
                        </div>
                        <div className={styles.quote_source}>
                            <img
                                src="/images/headshots/small/rob-godfrey-headshot-small.png"
                                alt=""
                                className={styles.quote_source_image}
                            />
                            <div className={styles.quote_source_text}>
                                <div className={styles.quote_source_name}>
                                    Rob Godfrey
                                </div>
                                <div className={styles.quote_source_title}>
                                    President & CEO
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default LeadershipPage;
