import styles from "./AboutUsPage.module.css";
import Header from "../../components/Header/Header";
import VideoHero from "../../components/VideoHero/VideoHero";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import Footer from "../../components/Footer/Footer";
import { useState } from "react";
import Timeline from "../../components/Timeline/Timeline";
import { timelineData } from "../../data/timeline";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import NumberedGridList from "../../components/NumberedGridList/NumberedGridList";
import { companyValues } from "../../data/lists";
import Modal from "../../components/Modal/Modal";
import usePageTitle from "../../hooks/usePageTitle";

function AboutUsPage() {
    usePageTitle("About Us");

    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <Header />
            <main id={styles.main}>
                <Modal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalStyle={{
                        maxWidth: 760,
                    }}
                >
                    <div id={styles.modal_content_container}>
                        <img
                            id={styles.modal_graphic}
                            src="/images/about-us-modal-graphic.png"
                            alt="A young woman smiling while looking at a laptop."
                        />

                        <p id={styles.modal_text}>
                            <strong>
                                Empowering institutions and organizations with
                                an AI-driven platform that enhances training and
                                promotes measurement-based care, while ensuring
                                the highest standards of security, compliance,
                                and data privacy.
                            </strong>
                            <br />
                            <br />
                            Our mandate is to provide a reliable, secure tool
                            that amplifies the transformative work of our
                            customers. We facilitate impactful training and care
                            measurement, acting solely as custodians of data,
                            with a firm commitment to never accessing or
                            altering customer data. Our focus is on enabling
                            organizations to achieve excellence in their
                            mission, with full confidence in the integrity and
                            privacy of their information.
                        </p>
                    </div>
                </Modal>
                <VideoHero id={styles.hero}>
                    <div id={styles.hero_top}>
                        <ArrowSubtitle text={"About Us"} />
                        <h1 className="main_h1">
                            AI of Tomorrow... <em>Today</em>
                        </h1>
                        <p>
                            We are an AI-Driven Digital Mental Health platform
                            that facilitates better training and
                            measurement-based care.
                        </p>
                        <button
                            onClick={() => setShowModal(true)}
                            id={styles.hero_learn_more}
                            className="orange_button"
                        >
                            Learn More
                            <img src="/images/up-right-arrow.svg" alt="" />
                        </button>
                    </div>
                    <div className={styles.hero_bottom}>
                        <div className={styles.hero_image_cards_container}>
                            <img
                                src="/images/about-us-laptop.jpg"
                                alt="A man wearing headphones while using a laptop"
                                className={styles.hero_image_card}
                            />
                            <img
                                src="/images/about-us-lab.jpg"
                                alt="A woman using a microscope in a laboratory"
                                className={styles.hero_image_card}
                            />
                            <img
                                src="/images/about-us-phone.jpg"
                                alt="A woman lying on a couch while using her phone for a video call"
                                className={styles.hero_image_card}
                            />
                        </div>
                    </div>
                </VideoHero>
                <div id={styles.content}>
                    <section id={styles.biomarkers}>
                        <div id={styles.biomarkers_content}>
                            <div id={styles.biomarkers_text_content}>
                                <SectionHeading
                                    mainStyle={{
                                        gap: 6,
                                        marginBottom: 0,
                                    }}
                                    arrowText={
                                        "Other mental health platforms keep you on the merry-go-round of care, but Keel delivers"
                                    }
                                    heading={"Better outcomes faster"}
                                    headingStyle={{
                                        color: "white",
                                        fontSize: 52,
                                        lineHeight: "68px",
                                    }}
                                />
                                <div
                                    id={styles.how}
                                    className={styles.biomarkers_subheading}
                                >
                                    <div>Taking mental health from</div>
                                    <div>
                                        <em>"how do you feel?"</em> to:
                                    </div>
                                </div>
                            </div>
                            <div
                                id={styles.real}
                                className={styles.biomarkers_subheading}
                            >
                                <div>
                                    What you feel is <em>real.</em>
                                </div>
                            </div>
                            <picture>
                                <source
                                    srcSet="/images/biomarkers-mobile-background.webp"
                                    media="(max-width: 830px)"
                                />
                                <img
                                    id={styles.biomarkers_graphic}
                                    src="images/biomarkers-full-background.webp"
                                    alt="Abstract graphic depicting the integration of Artificial Intelligence and Physical Biomarkers. On the left, a circular design labeled ‘Artificial Intelligence’ is connected by lines and dots, representing a digital network. On the right, a hexagonal design labeled ‘Physical Biomarkers’ features a similar network of interconnected points. Both shapes are linked by a central connector, symbolizing the convergence of AI and physical biomarker data. The background is a dark blue gradient, with bright blue lines and glowing dots highlighting the technological theme."
                                />
                            </picture>
                        </div>
                    </section>
                    <section id={styles.timeline}>
                        <div className={styles.timeline_top}>
                            <ArrowSubtitle
                                text={"Company history"}
                                dark={true}
                            />
                            <h2 style={{ maxWidth: 720 }}>
                                Keel Digital Solutions’ journey to mental health
                            </h2>
                            <p>
                                Keel Digital Solutions was founded in January
                                2021 as a COVID-19 testing laboratory. During
                                the 2021 Tokyo Summer Olympics, we watched as
                                Simone Biles raised her hand and came out about
                                her mental health struggles. We said to
                                ourselves… what if she could have known?
                                <br />
                                <br />
                                It was at that point that the founders thought
                                to revolutionize mental health support. Focused
                                on empowering real progress rather than
                                benefiting from continued struggles, the company
                                acquired a Mental Health software firm and
                                together developed Keel Mind, an AI-driven
                                platform offering personalized mental health
                                tools and peer support resources.
                                <br />
                                <br />
                                Early on, Keel Digital Solutions forged
                                impactful partnerships with post-secondary
                                institutions, indigenous groups and first
                                responder organizations. These collaborations
                                enhanced mental health services and peer
                                training using advanced AI technology.
                                <br />
                                <br />
                                By 2024, the company expanded its reach by
                                opening a second office in the United States.
                                They continued supporting first responders with
                                unique AI-based interview tools to facilitate
                                self-assessment and peer supporter selection.
                                Committed to genuine, ethical mental health
                                progress, the company continues to grow, driven
                                by strong leadership and strategic
                                collaborations.
                            </p>
                        </div>
                        <div className={styles.timeline_bottom}>
                            <Timeline data={timelineData} />
                        </div>
                    </section>

                    <section id={styles.what_we_value}>
                        <SectionHeading
                            arrowText={"Company values"}
                            darkArrowSubtitle={true}
                            heading={"Driven by purpose"}
                            headingStyle={{
                                fontFamily: "Inter",
                                fontWeight: "600",
                            }}
                            textStyle={{
                                maxWidth: 750,
                            }}
                        ></SectionHeading>
                        <NumberedGridList flow="column" data={companyValues} />
                    </section>
                </div>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default AboutUsPage;
