import { Link } from "react-router-dom";
import styles from "./HamburgerMenu.module.css";
import HamburgerDropdown from "../HamburgerDropdown/HamburgerDropdown";

function HamburgerMenu({ data, showMenu, setShowMenu, showSignIn = false }) {
    function handleCloseClick() {
        setShowMenu(false);
    }
    return (
        <div
            id={styles.hamburger_menu}
            className={(showMenu && styles.show) || ""}
        >
            <div id={styles.top}>
                <img
                    src="/images/keel-logo-white.svg"
                    alt="Keel logo"
                    id={styles.keel_logo}
                />
                <div id={styles.close_button} onClick={handleCloseClick}>
                    <img
                        src="/images/close-button.svg"
                        alt="hamburger menu button with three horizontal lines"
                    />
                </div>
            </div>
            <div id={styles.links_container}>
                {data.map((link) => (
                    <Link
                        key={link.name}
                        to={link.href}
                        className={styles.link}
                    >
                        {link.name}
                    </Link>
                ))}
                <div id={styles.bottom_links}>
                    {showSignIn && (
                        <HamburgerDropdown
                            text={"Sign in"}
                            className={styles.link}
                        >
                            <Link
                                target="_blank"
                                to={"https://app.us.geta-head.com/app/gah/"}
                                className={styles.link}
                            >
                                United States
                            </Link>
                            <Link
                                target="_blank"
                                to={"https://app.ca.geta-head.com/app/gah/"}
                                className={styles.link}
                            >
                                Canada
                            </Link>
                        </HamburgerDropdown>
                    )}
                    <Link to={"/contact-us"} className={styles.link}>
                        Contact us
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HamburgerMenu;
