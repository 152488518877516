import styles from "./JoiningOptionsPage.module.css";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import { dialInOptions } from "../../data/dial-in";
import usePageTitle from "../../hooks/usePageTitle";
import BasicPage from "../../components/BasicPage/BasicPage";

function JoiningOptionsPage({ forceDev = false }) {
    const isDev =
        // window.location.hostname === "localhost" ||
        // window.location.hostname === "dev.keel.digital" ||
        forceDev;

    usePageTitle("More Joining Options");
    return (
        <BasicPage>
            <div id={styles.heading}>
                <ArrowSubtitle text={"Join a Meeting"} dark={true} />
                <h1 className="main_h1 center">More dial-in options</h1>
                <p>
                    To join your meeting, dial one of the numbers below followed
                    by the pin number.
                </p>
            </div>
            <div className={styles.options_container}>
                {dialInOptions[isDev ? "dev" : "prod"].map(
                    ({ name, number }) => (
                        <div className={styles.option}>
                            <strong>{name}</strong>
                            <p className={styles.number}>{number}</p>
                        </div>
                    )
                )}
            </div>
        </BasicPage>
    );
}

export default JoiningOptionsPage;
